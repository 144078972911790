.roomItemOverlayContainer {
  position: absolute;
  pointer-events: none;
}

.floatingReference {
  position: relative;
  width: 100%;
  height: 100%;
}

.floatingScaleContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.isEditing {
  z-index: 1;
}
.isHovering {
  z-index: 2;
}
.isLongPressing {
  z-index: 2;
}
.newlyAddedItem {
  z-index: 2;
}

.noRoomMessage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-dark700);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-body);
  color: var(--color-yellow);
  text-align: center;
  font-size: 24px;
  padding: 80px;
  box-sizing: border-box;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--color-dark700);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.semitransparent {
  opacity: 0.5;
}

.space {
  background-color: var(--color-dark700);
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  overflow: hidden;
  position: relative;
}

.canvasAndSideBarContainer {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.canvasAndTopBarContainer {
  flex: 1;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  overflow: hidden;
}

.canvasSection {
  flex: 1;
  align-self: stretch;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.noControlCanvasSection {
  cursor: not-allowed;
}

.canvasContainer {
  position: relative;
}

.noControlCanvasContainer {
  pointer-events: none;
}

.remoteTmpCanvas {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.feelingsChartOverlayForTour {
  position: absolute;
  pointer-events: none;
}

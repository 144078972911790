.button {
  box-sizing: content-box;
  border: 0;
  outline: 0;
  padding: 0;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button.isSelected {
  outline-style: solid;
  outline-color: var(--color-dark700);
}

.button:hover {
  filter: brightness(85%);
}

.textButtonContainer .button {
  transition: filter 0.2s, outline 0.1s;
}

.hidden {
  visibility: hidden;
}

.textButtonContainer {
  position: relative;
}

.subButton {
  position: absolute;
  right: -6px;
  bottom: -6px;
}

.arrowContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.arrowIcon {
  transform: rotate(90deg) translateY(0px);
  transition: transform 0.2s ease-in-out;
}

.arrowContainer:hover .arrowIcon,
.isMenuOpen.arrowIcon {
  transform: rotate(90deg) translateY(3px);
}

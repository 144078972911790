.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
}

.activityContainer {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: row;
  position: relative;
}

.paywall {
  z-index: 60;
}

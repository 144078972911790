.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark700);
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  flex: 1 1;
  position: relative;
}

.navOuterContainer {
  display: flex;
  min-height: 60px;
  height: 60px;
}

@media (max-height: 600px) and (min-aspect-ratio: 1/1) {
  .container {
    flex-direction: row;
    height: unset;
    width: 100%;
  }

  .container .navOuterContainer {
    min-height: unset;
    height: unset;
    min-width: 60px;
    width: 60px;
  }

  .container .navInnerContainer {
    display: flex;
  }
}

.navInnerContainer {
  flex: 1;
}

.playerOuterContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  justify-content: center;
  align-items: center;
}

.playerContainer {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.outerOnlyPlayPauseControl {
  cursor: pointer;
}

.outerNoControl {
  cursor: not-allowed;
}

.innerNoControl {
  pointer-events: none;
}

.container {
  height: 36px;
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
}

.textContainer {
  position: relative;
  flex: 0 1 auto;
  min-width: 0;
  display: flex;
  align-items: center;
}

.textInput {
  background-color: var(--color-white);
  border: none;
  border-radius: 0;
  padding-right: 28px;
  padding-left: 32px;
  height: 100%;
  box-sizing: border-box;
  font-family: var(--font-body);
  font-size: 14px;
  width: 220px;
  border-radius: 18px;
  transition: outline 0.05s;
  outline: 1px solid var(--color-mid-gray);
  outline-offset: 0px;
  flex: 0 1 auto;
  min-width: 0;
}

.container:hover .textInput,
.textInput:focus,
.container:focus-within .textInput {
  outline: 2px solid var(--color-dark400);
}

.textInputSmallWidth {
  width: 200px;
}

.textInput::placeholder {
  color: var(--color-dark300);
}

.searchIcon {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 6px;
  z-index: 1;
  fill: var(--color-dark400);
}

.clearButton {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 28px;
  cursor: pointer;
}

.clearButton > .xIcon {
  height: 18px;
  width: 18px;
  margin-left: 2px;
  fill: var(--color-dark300);
  transition: fill 0.2s;
}

.clearButton:hover > .xIcon {
  fill: var(--color-dark500);
}

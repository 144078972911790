.container {
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.warningContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 28px;
  background-color: var(--color-yellow100);
  padding: 24px;
  border-radius: 12px;
}

.instructionalIcon {
  fill: var(--color-dark);
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 4px;
}

.subheading {
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: var(--color-dark);
  display: flex;
  margin-bottom: 14px;
}

.nameDisplayLabel {
  align-self: flex-start;
  margin-top: 10px;
  font-size: 14px;
}

.nameDisplay {
  padding: 4px 20px;
  border-radius: 10px;
  border: 1px solid var(--color-dark100);
  display: flex;
  align-items: center;
  background-color: var(--color-gray);
  overflow: hidden;
  word-break: break-all;
  text-align: start;
  font-size: 17px;
  color: var(--color-dark300);
}

.buttonRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 18px;
}

.buttonRow > button {
  flex-grow: 1;
}

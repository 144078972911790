.box {
  background-color: var(--color-gray);
  padding: 6px 10px 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-family: var(--font-body);
  font-size: 16px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  margin-top: 2px;
  user-select: text;
}

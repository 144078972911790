.containerAnchor {
  --button-size: 36px;
  --icon-size: 24px;
  --button-padding: calc((var(--button-size) - var(--icon-size)) / 2);
  --animation-duration: 0.3s;

  width: var(--button-size);
  height: var(--button-size);
}

.containerAnchorRelative {
  position: relative;
  width: 100%;
  height: 100%;
}

.container {
  position: absolute;
  right: 0;
  pointer-events: none;

  display: flex;
  flex-direction: row-reverse;

  opacity: 0;
  gap: 0px;
  margin: -24px -16px;
  padding: 24px 16px;
  transition-duration: var(--animation-duration);
  transition-property: opacity, gap, margin, padding;
}

.container.forceExpand {
  margin: 0;
  padding: 0;
  transition-property: opacity;
}

.container:hover,
.container.forceExpand {
  gap: 10px;
}

.container.show {
  pointer-events: auto;
  opacity: 1;
}

.buttonIcon {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: white;
}

.container .primaryButton {
  position: relative;
}

.container .secondaryButton {
  margin-right: calc(-1 * var(--button-size));
  opacity: 0;
  transition-duration: var(--animation-duration);
  transition-property: margin-right, opacity, background-color;
}

.container:hover .secondaryButton,
.container.forceExpand .secondaryButton {
  opacity: 1;
  margin-right: 0;
}

.navHeader {
  height: 60px;
  min-height: 60px;
  background-color: var(--color-yellow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
@media (max-height: 600px) and (min-aspect-ratio: 1/1) {
  .navHeader {
    height: unset;
    min-height: unset;
    flex-direction: column;
    width: 60px;
    min-width: 60px;
    padding: 20px 0;
  }
  .navHeader .rightGroup {
    flex-direction: column;
  }
  .navHeader .rightGroup > *:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.backButton {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backIcon {
  height: 26px;
  width: 26px;
}

.rightGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rightGroup > *:not(:first-child) {
  margin-left: 10px;
}

.button {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-yellow700);
  cursor: pointer;
}

.plus {
  fill: var(--color-dark);
  height: 36px;
  width: 36px;
}

.resetConfirmation .heading {
  font-size: 22px;
}

.resetConfirmation .confirmationText {
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
  color: var(--color-dark);
}

.resetConfirmation .buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resetConfirmation .buttonRow > *:not(:first-child) {
  margin-left: 10px;
}

.resetConfirmButton {
  background-color: var(--color-mid-gray);
}

.reset {
  fill: var(--color-dark);
  height: 35px;
  width: 35px;
  /* Centering the arrow circle inside the button circle */
  margin-top: -1px;
  transform: scaleX(-1);
}

.warnSourceButton {
  background-color: var(--color-orange);
}

.sourceIcon {
  height: 27px;
  width: 27px;
}

.messageText {
  font-size: 18px;
  color: var(--color-dark);
  font-family: var(--font-body);
}

.button {
  box-sizing: content-box;
  border: 0;
  outline: 0;
  padding: 0;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 0.2s, outline 0.1s;
}

.button.isSelected {
  outline-style: solid;
  outline-color: var(--color-dark700);
}

.button.isToggled {
  outline-style: solid;
  outline-color: var(--color-dark-gray);
}

.button:hover {
  filter: brightness(85%);
}

.hidden {
  visibility: hidden;
}

.colorWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  fill: var(--color-dark-gray);
  cursor: pointer;
}

.buttonContainer {
  position: relative;
  box-sizing: border-box;
}

.rainbowCircle {
  border: 0;
  border-radius: 50%;
  background: conic-gradient(
    var(--color-red),
    var(--color-orange),
    var(--color-yellow),
    var(--color-light-green),
    var(--color-light-blue),
    var(--color-blue),
    var(--color-purple),
    var(--color-red)
  );
  position: absolute;
  bottom: -6px;
  right: -6px;
  cursor: pointer;
  transition: filter 0.2s, outline 0.1s;
}

.rainbowCircle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  background: inherit;
  border-radius: 50%;
  filter: blur(2px);
  transform: translate(-50%, -50%);
}

.rainbowCircle:hover {
  filter: brightness(80%);
}

.rainbowCircle.isOpen {
  outline-style: solid;
  outline-color: var(--color-dark700);
}

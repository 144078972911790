.heading {
  font-size: 22px;
  margin-bottom: 24px;
}

.body {
  display: flex;
  margin-bottom: 30px;
  height: 300px;
  width: 500px;
  flex-wrap: wrap;
  justify-content: center;
}

.iconContainer {
  height: 60px;
  width: 60px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid transparent;
  padding: 3px;
  border-radius: 5px;
}

.selectedIcon {
  border-color: var(--color-yellow);
}

.iconContainer:hover {
  filter: brightness(115%);
}

.iconContainer:active {
  filter: brightness(107%);
}

.itemIcon {
  max-height: 100%;
  max-width: 100%;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray);
  margin-right: 10px;
}

.container {
  height: 67px;
  width: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}

.thumbnailContainer {
  background-color: var(--color-light);
}

.fallbackContainer {
  background-color: var(--color-yellow);
}

.thumbnailImage {
  height: 100%;
  width: 100%;
}

.fallbackIcon {
  height: 50%;
  width: 50%;
}

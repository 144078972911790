.container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;
  /*margin-bottom: 10px;*/
  color: var(--color-dark);
  /*align-self: flex-start;*/
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray) !important;
  margin-right: 6px;
}

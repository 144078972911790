.signOutButton {
  --height: 34px;
  --width: unset;
  --hover-brightness: brightness(95%);
  --bg-color: var(--color-light);
  align-self: flex-start;
  opacity: 90%;
}

.signOutButton:focus,
.signOutButton:not(.disabled):hover {
  box-shadow: var(--color-light) 0px 0px 0px 0px;
}

.signOutIcon {
  --size: 26px;
  height: var(--size);
  width: var(--size);
  fill: var(--color-red);
}

.signOutText {
  font-size: 16px;
  color: var(--color-red);
  margin-left: 2px;
  text-decoration-line: underline;
}

.billingCounter {
  background: var(--color-gray);
}

.wrapper {
  /* 95% height - vertical padding - header height */
  max-height: calc(95vh - 40px - 80px);
  overflow-x: visible;
  overflow-y: auto;
  padding: 0 6px;
}

.heading {
  font-size: 22px;
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  align-self: stretch;
}

.spacer {
  height: 20px;
}

.text {
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
}

.smallText {
  font-size: 13px;
  line-height: 15px;
  max-width: 350px;
  text-align: justify;
  align-self: flex-start;
  margin-bottom: 20px;
}

.warningText {
  font-size: 13px;
  line-height: 15px;
  max-width: 350px;
  text-align: justify;
  align-self: flex-start;
  margin-bottom: 10px;
}

.disabledText {
  color: var(--color-dark300);
}

.sectionHeader {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  height: 26px;
  margin-bottom: 10px;
  place-self: flex-start;
  display: flex;
  align-items: center;
}

.helpIcon {
  height: 22px;
  fill: var(--color-dark);
  margin-left: 10px;
}

.getSupport {
  margin-top: 10px;
}

.row .tourButton,
.row .supportButton {
  width: 80px;
  height: 32px;
  font-size: 14px;
}

.menuContainer {
  width: 300px;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 0;
  z-index: 51;
  cursor: default;
  font-family: var(--font-body);
  color: var(--color-dark);
  max-height: 100vh;
  overflow-y: auto;
}

.sectionHeader {
  flex: 1;
  font-size: 13.6px;
  text-align: left;
  padding: 5px 20px;
}

.sectionSeparator {
  flex: 1;
  background-color: var(--color-gray200);
  border-color: var(--color-gray200);
  margin: 5px 20px;
}

.menuItem {
  display: inline-flex;
  align-items: center;

  padding: 5px 10px 5px 20px;

  cursor: pointer;
  transition: background-color 0.1s;
  background-color: var(--color-light);
}

.menuItem:not(.disabled):hover {
  background-color: var(--color-yellow200);
}

.disabled {
  color: var(--color-dark300);
  cursor: default;
}

.menuItemLabel {
  display: inline-block;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
}

.checkmark {
  width: 20px;
  height: 20px;
}

.checkmark > svg {
  width: 100%;
  height: 100%;
}

.checkmark > svg *:nth-child(2) {
  fill: var(--color-dark);
  stroke: var(--color-dark);
}

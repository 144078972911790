.tooltip {
  position: absolute;

  padding: 4px 10px;
  width: max-content;
  max-width: 170px;
  align-items: center;
  border-radius: 4px;
  background: var(--color-light);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);

  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: opacity 0.2s;
  opacity: 0;
  color: var(--color-dark);
  font-family: var(--font-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
}

.tooltip.show {
  opacity: 0.8;
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 10px 5px 20px;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.1s;
  background-color: var(--color-light);
}

.container:hover {
  background-color: var(--color-yellow200);
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  /*DARK*/
  filter: brightness(0) saturate(100%) invert(23%) sepia(12%) saturate(987%)
    hue-rotate(119deg) brightness(90%) contrast(93%);
}

.label {
  translate: 0 1px;
}

.arrow {
  align-self: flex-end;
  border-radius: 50%;
}

.arrow.open {
  background: var(--color-yellow);
}

.submenuContainer {
  width: 193px;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 22;
}

.submenuContainerElevated {
  z-index: 32;
}

.wrapper {
  min-height: 0;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex: 1 1;
}

.wrapperNoControl {
  cursor: not-allowed;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 100%;
  flex: 1;
}

@media (max-height: 600px) and (min-aspect-ratio: 1/1) {
  .page {
    flex-direction: row;
  }
}

.pageNoControl {
  pointer-events: none;
}

.whiteboard {
  background-color: var(--color-dark700);
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 0;
  position: relative;
  overflow: hidden;
}

.canvasSectionWrapper {
  flex: 1;
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.canvasSection {
  flex: 1;
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.canvasSectionWithTopAndBottomFree {
  align-items: center;
}

.canvasContainer {
  position: relative;
}

.remoteTmpCanvas {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--color-dark700);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

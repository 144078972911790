.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.checkIcon {
  margin-bottom: 3px;
}

.compactButton {
  all: unset;
  color: inherit;
  font: inherit;
  cursor: pointer;

  height: 28px;
  width: 28px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  transition: background-color 0.2s;
}

.compactButton:hover {
  background-color: var(--color-yellow200);
}

.compactIcon {
  height: 20px;
  width: 20px;
}

.container {
  --border-radius: 100%;
  border-radius: var(--border-radius);
  --radius: 100px;
  height: var(--radius);
  width: var(--radius);
  border: var(--color-dark700) 2px solid;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark);
  z-index: 41;
  position: relative;
}

:global(.fullScreenVideoContainer) .container {
  --border-radius: 70px;
}

:global(.fullScreenVideoContainer) .localContainer {
  --border-radius: 20px;
}

:global(.fullScreenVideoContainer) .container:hover button {
  visibility: visible;
}

@media (min-width: 900px) {
  .container {
    --radius: 120px;
  }
}

:global(.minimizedVideoContainer) .container:hover {
  z-index: 43;
}

:global(.fullScreenLocalVideo) {
  position: absolute;
  z-index: 42;
}

.message {
  color: var(--color-yellow);
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-wrap: balance;
  padding: 10px;
}

.fullScreenMessage {
  font-size: 20px;
}

.retrying {
  color: var(--color-dark400);
}

.button {
  margin-top: 7px;
  height: 20px;
  width: 78px;
  font-size: 10px;
}

.button:hover {
  background-color: var(--color-yellow200);
}

.button:active {
  background-color: var(--color-yellow700);
}

.blurLoadingIndicator {
  position: absolute;
}

.buttonContainer {
  z-index: 43;
  position: absolute;
}

:global(.minimizedVideoContainer) .buttonContainer {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(var(--angle-position))
    translate(60px, 0) rotate(calc(-1 * var(--angle-position)));
}

.mediaMenuButton {
  --angle-position: 15deg;
}

.muteButton,
.mutedIndicator {
  --angle-position: 48deg;
}

.blurButton,
.disableVideoButton {
  --angle-position: 81deg;
}

.connectionQualityIndicator {
  --angle-position: 225deg;
}

.localContainer .disableVideoButton {
  --angle-position: 114deg;
}

.canControlCursorButton {
  --angle-position: 114deg;
}

.buttonsWrapper {
  pointer-events: none;
}

.buttonsWrapper > * {
  pointer-events: auto;
}

:global(.fullScreenVideoContainer) .buttonsWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:global(.fullScreenVideoContainer) .mediaMenuButton {
  right: 0;
  bottom: 50px;
  transform: translate(50%, 50%);
}

:global(.fullScreenVideoContainer) .muteButton,
:global(.fullScreenVideoContainer) .mutedIndicator {
  right: 0px;
  bottom: 70px;
  transform: translate(50%, 50%);
}

:global(.fullScreenVideoContainer) .localContainer .muteButton,
:global(.fullScreenVideoContainer) .localContainer .mutedIndicator {
  bottom: 0;
}

:global(.fullScreenVideoContainer) .blurButton,
:global(.fullScreenVideoContainer) .disableVideoButton {
  right: 0;
  bottom: 0;
}

:global(.fullScreenVideoContainer) .localContainer .blurButton {
  right: 50px;
  bottom: 0;
  transform: translate(50%, 50%);
}

:global(.fullScreenVideoContainer) .localContainer .connectionQualityIndicator {
  transform: translate(-50%, -50%);
}

:global(.fullScreenVideoContainer) .localContainer .disableVideoButton {
  left: unset;
  top: unset;
  right: 100px;
  bottom: 0;
  transform: translate(50%, 50%);
}

:global(.fullScreenVideoContainer) .canControlCursorButton,
:global(.fullScreenVideoContainer) .canControlCursorIndicator {
  right: 70px;
  bottom: 0;
  transform: translate(50%, 50%);
}

:global(.fullScreenVideoContainer) .localContainer .canControlCursorButton,
:global(.fullScreenVideoContainer) .localContainer .canControlCursorIndicator {
  right: 100px;
}

:global(.fullScreenVideoContainer) .lastPeer .muteButton,
:global(.fullScreenVideoContainer) .lastPeer .mutedIndicator {
  left: 0px;
  top: unset;
  right: unset;
  bottom: 70px;
  transform: translate(-50%, 50%);
}

:global(.fullScreenVideoContainer) .lastPeer .blurButton,
:global(.fullScreenVideoContainer) .lastPeer .disableVideoButton {
  left: 0;
  top: unset;
  right: unset;
  bottom: 0;
  transform: unset;
}

:global(.fullScreenVideoContainer) .lastPeer .canControlCursorButton,
:global(.fullScreenVideoContainer) .lastPeer .canControlCursorIndicator {
  left: 70px;
  top: unset;
  right: unset;
  bottom: 0px;
  transform: translate(-50%, 50%);
}

.expandButtonContainer {
  z-index: 43;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, 0);
}

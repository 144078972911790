.modal {
  padding: 0;
}

.heading {
  font-size: 28px;
  margin-bottom: 24px;
}

.button {
  --width: 400px;
  --height: 50px;
  font-size: 22px;
  padding: 0 20px;
  display: initial;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

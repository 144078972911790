.heading {
  font-size: 22px;
}

.subheading {
  font-size: 12px;
  margin-bottom: 20px;
}

.nameInputLabel {
  align-self: flex-start;
  padding-left: 2px;
}

.nameInput {
  width: calc(100% - 22px);
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray);
  margin-right: 10px;
}

.heading {
  font-size: 22px;
  margin-bottom: 20px;
  display: flex;
  align-self: flex-start;
  align-items: center;
}

.folderSharedIcon {
  width: 24px;
  margin-right: 10px;
}

.backArrowIcon {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  padding: 6px 6px;
  margin-right: 18px;
  cursor: pointer;
  fill: var(--color-dark);
  border-radius: 20%;
  transition: background-color 0.2s;
}

.backArrowIcon:hover {
  background-color: var(--color-mid-gray);
}

.roomRowsContainer {
  width: 600px;
  height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-top: 1px solid var(--color-dark200);
  border-bottom: 1px solid var(--color-dark200);
  margin-bottom: 20px;
}

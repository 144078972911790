.heading {
  font-size: 22px;
  margin-bottom: 10px;
}

.copyTextBox {
  margin-bottom: 16px;
}

.text {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
  max-width: 300px;
}

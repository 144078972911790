.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  align-self: stretch;
  cursor: pointer;
}

.container:not(:last-child) {
  border-bottom: 1px solid var(--color-mid-gray);
}

.container:hover {
  background-color: var(--color-gray);
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.shareFolderIcon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

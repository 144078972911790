.heading {
  font-size: 22px;
}

.nameInputLabel {
  margin-top: 10px;
  align-self: flex-start;
}

.nameInput {
  width: calc(100% - 20px);
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray);
  margin-right: 10px;
}

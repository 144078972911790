.card {
  --scale-factor: 1;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: max-content;
  max-width: calc(var(--scale-factor) * 280px);
  padding-right: calc(var(--scale-factor) * 8px);
  align-items: center;
  gap: calc(var(--scale-factor) * 10px);
  border-radius: calc(var(--scale-factor) * 12px);
  background: rgba(255, 255, 255, 0.6);
  --webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  overflow: hidden;
  transition: opacity 0.2s;
  opacity: 0;
}

.card.scaleUp {
  --scale-factor: 1.2;
}

.card.show {
  opacity: 1;
}

.thumbnailContainer {
  width: calc(var(--scale-factor) * 60px);
  height: calc(var(--scale-factor) * 60px);
}

.thumbnailContainer > div {
  width: calc(var(--scale-factor) * 60px);
  height: calc(var(--scale-factor) * 60px);
}

.cardBody {
  display: flex;
  flex-direction: row;
  gap: calc(var(--scale-factor) * 4px);
  align-items: center;
  margin: calc(var(--scale-factor) * 8px) 0;
}

.card .cardIconContainer {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  height: calc(var(--scale-factor) * 20px);
}

.card .cardIcon {
  width: calc(var(--scale-factor) * 16px);
  height: calc(var(--scale-factor) * 16px);
  fill: var(--color-dark);
}

.card .cardIcon :global(.setFill) {
  fill: var(--color-dark);
}

.card .cardIcon :global(.setStroke) {
  stroke: var(--color-dark);
}

.cardText {
  flex: 1 0 0;
  color: var(--color-dark);
  font-family: var(--font-body);
  font-size: calc(var(--scale-factor) * 18px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}

.cardText.breakAll {
  word-break: break-all;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  align-self: stretch;
  cursor: pointer;
}

.container:not(:last-child) {
  border-bottom: 1px solid var(--color-mid-gray);
}

.container:hover:not(.selected) {
  background-color: var(--color-gray);
}

.selected {
  background-color: var(--color-mid-gray);
}

.currentRoom {
  font-weight: bold;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.description {
  font-size: 12px;
  margin-right: 10px;
  white-space: nowrap;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.iconContainerSelected {
  display: flex;
}

.initialsIconWrapper {
  margin-right: 8px;
}

.circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-dark);
}

.initialsIcon {
  padding-top: 1px;
  font-size: 11px;
}

.copyIcon {
  margin-right: 6px;
}

.copyIconExtraMargin {
  margin-right: 26px;
}

.dropIcon {
  height: 16px;
  margin-right: 6px;
}

.rowButton {
  all: unset;
  color: inherit;
  font: inherit;
  cursor: pointer;
  height: 20px;
  width: 20px;
  /*dark300*/
  filter: brightness(0) saturate(100%) invert(82%) sepia(10%) saturate(143%)
    hue-rotate(116deg) brightness(86%) contrast(92%);
  transition: filter 0.2s;
}

.rowButton:hover {
  filter: none;
}

.rowIcon {
  fill: var(--color-dark);
  height: 20px;
  width: 20px;
}

.sharedRoomItemsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 2px 24px 18px 24px;
  width: calc(100% - 48px);

  gap: 8px;
  background-color: var(--color-mid-gray);

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.resourceContainer {
  flex: 1 1 0px;
  min-width: 0px;
  height: 84px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  cursor: default;
}

.textInput {
  border: 1px solid var(--color-dark200);
  border-radius: 2px;
  background-color: var(--color-gray);
  padding: 8px;
  height: 12px;
  font-family: var(--font-body);
  font-size: 18px;
  flex: 1;
  margin-right: 10px;
  z-index: 21;
  min-width: 0px;
}

:root {
  /* fonts */
  --font-body: Catamaran, sans-serif;
  --font-mid: Taviraj, serif;
  --font-heading: Comfortaa, sans-serif;
  --font-billing: Poppins, sans-serif;

  /* colors */
  --color-white: #ffffff;
  --color-light: #fbfbfa;
  --color-light-gray: #f6f6f6;
  --color-gray: #f0f0f0;
  --color-mid-gray: #e5e5e5;
  --color-dark-gray: #b4b4b4;
  --color-dark100: #d4d9d8;
  --color-dark200: #aab3b1;
  --color-dark300: #7f8c8a;
  --color-dark400: #556663;
  --color-dark: #2a403c;
  --color-dark700: #192624;
  --color-yellow100: #fdf7db;
  --color-yellow200: #fcefb6;
  --color-yellow300: #f9e9a1;
  --color-yellow400: #f8de6c;
  --color-yellow: #f7d749;
  --color-yellow700: #efc300;
  --color-light-green: #99ae5f;
  --color-green400: #35948d;
  --color-green: #037971;
  --color-red200: #eabdb7;
  --color-red: #ca594a;
  --color-blue200: #c0d0e2;
  --color-light-blue: #3dadd2;
  --color-blue: #618ab6;
  --color-orange: #eaac42;
  --color-purple: #8445d6;

  /* toastify */
  --toastify-font-family: var(--font-body);

  --toastify-color-light: var(--color-light);
  --toastify-text-color-light: var(--color-dark);

  --toastify-color-success: var(--color-green400);
  --toastify-icon-color-success: var(--color-green400);

  --toastify-color-error: var(--color-red);
  --toastify-icon-color-error: var(--color-red);

  --toastify-color-info: var(--color-blue);
  --toastify-icon-color-info: var(--color-blue);

  --toastify-color-warning: var(--color-orange);
  --toastify-icon-color-warning: var(--color-orange);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*Disable touchpad gestures like pull-to-refresh and pull from side to go back*/
  overscroll-behavior: contain; /*for Chrome back gesture*/
  overflow: hidden; /*for Safari 15 or lower*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  user-select: none;
}

.container {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.emailInput {
  width: 350px;
}

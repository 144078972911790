.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 20px 5px 20px;
  align-self: stretch;
  cursor: pointer;
}

.container:not(:last-child) {
  border-bottom: 1px solid var(--color-mid-gray);
}

.container:hover:not(.selected) {
  background-color: var(--color-gray);
}

.selected {
  background-color: var(--color-mid-gray);
}

.currentRoom {
  font-weight: bold;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iconContainer {
  display: flex;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s;
}

.container:hover > .iconContainer {
  pointer-events: auto;
  opacity: 1;
}

.shareIcon {
  margin-right: 8px;
}

.iconButton {
  all: unset;
  color: inherit;
  font: inherit;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.rowIcon {
  height: 20px;
  width: 20px;
  fill: var(--color-dark);
  /*dark300*/
  filter: brightness(0) saturate(100%) invert(82%) sepia(10%) saturate(143%)
    hue-rotate(116deg) brightness(86%) contrast(92%);
  transition: filter 0.2s;
}

.rowIcon:hover {
  filter: none;
}

.textInput {
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  border: 1px solid var(--color-dark200);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 6px 10px 6px 10px;
  height: 24px;
  font-family: var(--font-body);
  font-size: 16px;
  color: var(--color-dark);
  outline: 0px solid var(--color-dark400);
  transition: outline 0.1s, border 0.2s;
}

.textInput:hover {
  border-color: var(--color-dark400);
}

.textInput:focus {
  border-color: var(--color-dark400);
  outline-width: 1px;
  z-index: 1;
}

.wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
}

.wrapper .placeholder {
  color: var(--color-dark200);
}

.wrapper .dropDown {
  cursor: pointer;
  border: 1px solid var(--color-dark200);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 0px 10px;
  height: 36px;
  width: calc(100% - 20px - 2px);
  font-family: var(--font-body);
  font-size: 14px;
  display: flex;
  align-items: center;
  outline: 0px solid var(--color-dark400);
  transition: outline 0.1s, border 0.2s;
}

.wrapper .dropDown:hover {
  border-color: var(--color-dark400);
}

.wrapper .dropDown:focus {
  border-color: var(--color-dark400);
  outline-width: 1px;
}

.wrapper .iconLabelContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.wrapper .icon {
  margin-right: 8px;
}

.wrapper .arrow {
  position: absolute;
  height: 16px;
  top: calc(50% - 8px - 10px);
  right: 10px;
}

.wrapper > ul {
  width: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  background: var(--color-white);
  font-family: var(--font-body);
  border-radius: 10px;

  transition: max-height 0.1s, border 0.1s;
  max-height: 0vh;
  overflow: auto;
  border: 0px solid var(--color-dark);
}

.wrapper > ul.open {
  border: 1px solid var(--color-dark);
  max-height: 40vh;
}

.wrapper > ul > li {
  cursor: pointer;
  display: flex;
  padding: 0px 10px;
}

.wrapper > ul > li:hover {
  background: var(--color-yellow200);
}

.wrapper > ul > li:focus {
  background: var(--color-yellow200);
  outline: 2px solid var(--color-dark400);
}

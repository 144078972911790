.clickBlocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  cursor: not-allowed;
}

.clickBlockerHidden {
  pointer-events: none;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-body);
  text-align: center;
  transition: opacity 0.2s;
}

.overlayHidden {
  opacity: 0%;
  pointer-events: none;
}

.message {
  font-size: 28px;
  color: var(--color-yellow);
}

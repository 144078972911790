.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--color-dark700);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.text {
  font-family: var(--font-body);
  color: var(--color-dark700);
  font-size: 18px;
}

.nameInput {
  color: var(--color-dark700);
  width: 300px;
  margin-top: 7px;
  margin-bottom: 40px;
  font-size: 18px;
}

.button {
  width: 200px;
  margin-bottom: 40px;
}

.button.topMargin {
  margin-top: 30px;
}

.thankYouRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.check {
  margin-right: 10px;
}

.smallText {
  font-family: var(--font-body);
  font-size: 16px;
  font-style: italic;
  color: var(--color-dark700);
}

.bottomMargin {
  margin-bottom: 20px;
}

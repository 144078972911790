.modal {
  background-color: var(--color-light);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: var(--color-dark);
  position: relative;
}

.close {
  position: absolute;
  right: 20px;
  z-index: 41;
}

.xIcon {
  --size: 30px;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
  fill: var(--color-dark);
  border-radius: 20%;
  transition: background-color 0.2s;
}

.xIcon:hover {
  background-color: var(--color-mid-gray);
}

.content {
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

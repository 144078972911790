.container {
  height: 32px;
  width: 80px;
  border-radius: 50px;
  background-color: var(--color-mid-gray);
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow 0.1s, background-color 0.3s;
  box-shadow: var(--color-light) 0px 0px 0px 0px;
  position: relative;
}
.container:hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 3px;
}

.active {
  background-color: var(--color-yellow);
}

.disabled {
  background-color: var(--color-dark300);
  cursor: default;
  box-shadow: none;
}

.disabled:hover {
  box-shadow: none;
}

.circle {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: var(--color-light);
  position: absolute;
  top: 4px;
  left: 4px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.active .circle {
  transform: translateX(48px);
}

.container:not(.active) .textContainer {
  margin-left: 32px;
}

.container.active .textContainer {
  margin-right: 32px;
}

.textContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--color-dark);
  font-family: var(--font-body);
  line-height: 14px;
  text-align: center;
  margin-top: 2px;
}

.container {
  position: relative;
  flex: 1;
  min-width: 0;
  height: 36px;
  border-radius: 18px;
  background-color: var(--color-white);
}

.activeContainer {
  z-index: 2;
}

.topPart {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.1s, box-shadow 0.1s;
  box-shadow: var(--color-mid-gray) 0px 0px 0px 1px;
  border-radius: 18px;
  overflow: hidden;
}

.activeTopPart {
  box-shadow: var(--color-dark400) 0px 0px 0px 2px;
}

.topPart:hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 2px;
}

.topPartWithoutValue:hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 2px;
}

.leftPart {
  height: 100%;
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
}

.leftPartWithValue {
  transition: background-color 0.1s;
  background-color: var(--color-yellow400);
}

.leftPartWithValue:hover {
  background-color: var(--color-yellow300);
}

.text {
  padding-left: 10px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-dark300);
  font-size: 14px;
  margin-right: -10px;
}

.textWithValue {
  color: var(--color-dark);
  margin-right: -5px;
}

.rightButton {
  width: 36px;
  min-width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  width: 24px;
  min-width: 24px;
  padding-right: 2px;
  transition: background-color 0.1s;
  background-color: var(--color-yellow400);
}

.closeButton:hover {
  background-color: var(--color-yellow300);
}

.expandIcon {
  height: 24px;
  width: 24px;
  fill: var(--color-dark400);
}

.openExpandIcon {
  transform: rotate(180deg);
}

.xIcon {
  height: 18px;
  width: 18px;
}

.dropDown {
  position: absolute;
  top: 36px;
  width: 200px;
  box-sizing: border-box;
  background-color: var(--color-white);
  padding-right: 10px;
  padding-left: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.rightAlign {
  right: 0;
}

.body {
  max-height: 300px;
  overflow: auto;
}

.bodyWithScrollbar {
  margin-right: -10px;
  margin-bottom: 0;
}

.container {
  display: grid;
  grid-template:
    "preview preview" 1fr
    "info    buttons" 64px
    / 1fr auto;
  height: 80vh;
  width: 80vw;
  margin-top: 40px;
}

.preview {
  grid-area: preview;
  overflow-y: auto;
  background-color: var(--color-white);
  margin-bottom: 20px;
}

.preview:not(.zoomed) {
  display: flex;
  align-items: center;
  justify-content: center;
}

:not(.zoomed) > .imgPreview {
  max-height: 100%;
  max-width: 100%;
}

.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: var(--color-dark);
  margin-right: 30px;
}

a.button {
  color: unset;
  text-decoration: unset;
}

.button svg {
  width: 40px;
  height: 40px;
}

.button img {
  max-width: 40px;
  max-height: 40px;
}

.button > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox {
  margin: 0;
}

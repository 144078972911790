.container {
  --btn-size: 24px;
  height: 90px;
  width: 100%;
  background-color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px 12px 20px;
  box-sizing: border-box;
  font-family: var(--font-body);
  color: var(--color-dark);
}

.itemContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.itemContainerRight {
  justify-content: flex-end;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.roomNameContainer {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.viewerButton {
  all: unset; /* Resets all inherited and default styles */
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.viewerButton:hover {
  background-color: var(--color-yellow200);
}

.eyeIcon {
  height: 20px;
  width: 20px;
  fill: var(--color-dark);
}

.viewerTooltipWrapper {
  margin-right: 12px;
  border-radius: 50%;
}

.roomName {
  cursor: text;
  max-width: 25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.noRoomName {
  color: var(--color-dark200);
  cursor: default;
}

.textInput {
  border: 1px solid var(--color-dark200);
  border-radius: 10px;
  background-color: var(--color-gray);
  padding: 8px;
  height: 18px;
  font-family: var(--font-body);
  font-size: 22px;
  /*display: none;*/
  width: 270px;
  z-index: 21;
}

.roomMenuAndIconContainer {
  position: relative;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expandIcon {
  transform: rotate(180deg);
  cursor: pointer;
  height: var(--btn-size);
  width: var(--btn-size);
  border-radius: 50%;
}

.inactiveIcon {
  background-color: var(--color-light);
  transition: background-color 0.1s;
}

.inactiveIcon:hover {
  background-color: var(--color-yellow200);
}

.activeIcon {
  /* green */
  filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(6008%)
    hue-rotate(170deg) brightness(94%) contrast(98%);
  transition: none;
  background-color: transparent;
}

.statusText {
  font-size: 16px;
  color: var(--color-dark200);
  margin-top: -6px;
}

.activeStatusText {
  color: var(--color-green);
}

.intercomSpacer {
  width: 66px;
}

.verticalLine {
  width: 1px;
  height: 48px;
  border-radius: 1px;
  background-color: var(--color-dark100);
  margin: 6px;
}

.clientButtonContainer {
  position: relative;
}

.clientButton .clientSelectedLabel {
  color: var(--color-green);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 10px);
}

.switchClientButton {
  position: absolute;
  top: 0;
  right: 12px;
}

button.activableButton {
  background: transparent;
  transition: background 0.5s;
}

button.active {
  background: var(--color-yellow200);
}

.clientSelected {
  animation: 0.5s ease-in 0s 6 alternate none running selectionAnimation;
}

@keyframes selectionAnimation {
  from {
    background: transparent;
  }

  to {
    background: var(--color-yellow200);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
}

.settingsItemContainer {
  position: relative;
}

.waitingRoomItemContainer {
  position: relative;
}

.notificationDot {
  position: absolute;
  top: 4px;
  right: 20px;
  border-radius: 50%;
}

.instructionalFilledIcon {
  height: 18px;
  width: 18px;
}

.instructionalFilledIconLow:not(.instructionalFilledIconMax) {
  fill: var(--color-orange);
}

.instructionalFilledIconMax {
  fill: var(--color-red);
}

.notificationDotWaiting {
  height: 16px;
  width: 16px;
  background-color: var(--color-green400);
}

.billingTooltip {
  padding: 0;
  border-radius: 12px;
  pointer-events: unset;
  white-space: unset;
  z-index: 61;
}

.button {
  --height: 36px;
  --width: 170px;
  --hover-brightness: brightness(97%);
  --bg-color: var(--color-yellow);
  cursor: pointer;
  height: var(--height);
  width: var(--width);
  color: var(--color-dark);
  font-size: calc(var(--height) / 2);
  font-family: var(--font-body);
  background-color: var(--bg-color);
  border: none;
  border-radius: calc(var(--height) / 2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow, filter 0.1s;
  box-shadow: var(--color-light) 0px 0px 0px 0px;
  flex-shrink: 0;
}

.link {
  text-decoration: inherit;
}

.button:focus,
.button:not(.disabled):hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 3px;
  outline: none;
  filter: var(--hover-brightness);
}

.button:not(.disabled):active {
  filter: var(--hover-brightness);
}

.button.disabled {
  background-color: var(--color-gray);
  color: var(--color-dark200);
  cursor: default;
}

.button.secondary {
  background-color: var(--color-gray);
}

.starIcon {
  height: 24px;
  width: 24px;
  fill: var(--color-dark400);
}

.filled {
  fill: var(--color-yellow700);
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.buttonMarginBottom {
  margin-bottom: 6px;
}

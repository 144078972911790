.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-dark700);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-body);
  color: var(--color-yellow);
  text-align: center;
  font-size: 24px;
  padding: 80px;
  box-sizing: border-box;
  z-index: 50;
}

.container.transparent {
  background-color: rgba(0, 0, 0, 0.8);
}

.inviteSection {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 20px;
}

.linkRow {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.link {
  margin-right: 10px;
  font-size: 18px;
  font-style: normal;
  user-select: text;
}

.signInButton {
  margin-top: 20px;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.buttonGroup > :not(:first-child) {
  margin-top: 20px;
}
.resetRoomButton {
  margin-top: 20px;
}

.largeButton {
  width: 250px;
}

.body {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 4px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  padding-right: 5px;
}

.wide {
  flex: 2;
}

.regularWidth {
  flex: 1;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}

.value {
  color: var(--color-dark400);
  margin-top: 1px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
}

.icon {
  flex: 1 0 auto;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  fill: var(--color-dark200);
}

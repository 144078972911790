.button {
  box-sizing: content-box;
  border: 0;
  outline: 0;
  padding: 0;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu {
  background: var(--color-light);
  position: absolute;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  gap: 10px;
}

.menu .menuOption {
  transition: filter 0.2s;
  border: 0;
}

.menu .menuOption:hover {
  filter: brightness(85%);
}

.menu .menuOption.isSelected {
  outline-style: solid;
  outline-color: var(--color-dark700);
}

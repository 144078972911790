.container {
  position: relative;
  height: 26px;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: var(--color-light);
  border: solid 1px var(--color-mid-gray);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: var(--color-light);
  border: solid 1px var(--color-mid-gray);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: var(--color-gray);
}

input[type="range"]::-webkit-slider-thumb:active {
  border: solid 1px var(--color-yellow);
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 178px;
  position: absolute;
  top: 10px;
  background-color: var(--color-mid-gray);
  pointer-events: none;
}

input[type="range"].fromInput {
  height: 0;
  z-index: 1;
}

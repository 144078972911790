.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  background-color: var(--color-white);

  border-bottom: 1px solid var(--color-gray);
}

.header {
  font-family: var(--font-body);
  color: var(--color-dark);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.xIcon {
  --size: 30px;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
  fill: var(--color-dark);
  border-radius: 20%;
  transition: background-color 0.2s;
}

.xIcon:hover {
  background-color: var(--color-mid-gray);
}

.body {
  padding: 30px 54px;
  display: flex;
  flex-direction: column;
}

.message {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.horizontalLine {
  background-color: var(--color-gray);
  height: 3px;
  width: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 2px;
}

.sectionHeader {
  margin-bottom: 20px;
  align-self: flex-start;
  font-family: var(--font-heading);
  width: 100%;
}

.modal {
  z-index: 50;
}

.heading {
  font-size: 22px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.backContainer {
  display: flex;
  position: absolute;
  left: 0;
  align-items: center;
}

.backArrowIcon {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  padding: 6px 6px;
  margin-right: 18px;
  cursor: pointer;
  fill: var(--color-dark);
  border-radius: 20%;
  transition: background-color 0.2s;
}

.backArrowIcon:hover {
  background-color: var(--color-mid-gray);
}

.toggle {
  margin: 0 10px;
}

.pricingPageIFrame {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 12px;
}

.embeddedCheckout {
  max-height: 80vh;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  overflow-y: auto;
}

.bodyWrapper {
  height: 80vh;
  width: 80vw;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: transparent;
  transition: background-color 0.5s;
}

.yellowBackground {
  background-color: var(--color-yellow);
}

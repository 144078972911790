.buttonContainer:hover .button {
  visibility: visible;
}

.button {
  --size: 30px;
  /*Overwrite safari ipad default button padding*/
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: var(--size);
  width: var(--size);
  background-color: var(--color-white);
  border-radius: 50%;
  transition: box-shadow 0.1s, border-radius 200ms;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.25);
  border: none;
  visibility: hidden;
}

.button:hover {
  box-shadow: 0 0 0px 4px var(--color-dark400);
  background-color: var(--color-gray);
}

.fullScreenButton {
  --size: 40px;
  height: var(--size);
  width: var(--size);
}

.button.visible {
  visibility: visible;
}

:global(.minimizedVideoContainer):hover .button {
  visibility: visible;
}

:global(.minimizedVideoContainer):not(:hover) .button.indicator:not(:hover),
:global(.fullScreenVideoContainer)
  :global(.singleVideoContainer):not(:hover)
  .button.indicator {
  background-color: var(--color-dark700);
  border-radius: 25%;
}

:global(.minimizedVideoContainer):not(:hover)
  .button.indicator:not(:hover)
  .icon,
:global(.fullScreenVideoContainer)
  :global(.singleVideoContainer):not(:hover)
  .button.indicator
  .icon {
  fill: var(--color-light);
}

.icon {
  --size: 18px;
  height: var(--size);
  width: var(--size);
  fill: var(--color-dark);
  /* Without pointer-events: none, on touchscreens, the video buttons are hidden after clicking on the blur button
     because the icon element changes. */
  pointer-events: none;
}

.icon.red {
  fill: var(--color-red);
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark700);
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  flex: 1 1;
  position: relative;
}

.navOuterContainer {
  display: flex;
  min-height: 60px;
  height: 60px;
  z-index: 1;
}

@media (max-height: 600px) and (min-aspect-ratio: 1/1) {
  .container {
    flex-direction: row;
    height: unset;
    width: 100%;
  }

  .container .navOuterContainer {
    min-height: unset;
    height: unset;
    min-width: 60px;
    width: 60px;
  }

  .container .navInnerContainer {
    display: flex;
  }
}

.navInnerContainer {
  flex: 1;
}

.videoContainerDisabledComponent {
  flex: 1;
}

.videoOuterContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.videoContainer {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video {
  touch-action: none;
  max-width: 100%;
  max-height: 100%;
}

.outerNoControl {
  cursor: not-allowed;
}

.innerNoControl {
  pointer-events: none;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--color-dark700);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* Applying z-index to show the spinner on top of everything,
     and keeping the spinner on the background when hidden.
     This way, if the video is transparent (waiting for the video frames
     to arrive), the spinner will still be visible. */
  z-index: 1;
}

.loadingContainer.loadingContainerHidden {
  z-index: 0;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 100px;
  cursor: pointer;
}

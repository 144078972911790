.selectorContainer {
  display: flex;
  flex-direction: row;
}

.backgroundImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundImage {
  border: solid var(--color-dark);
  cursor: pointer;
}

.backgroundImage:hover {
  filter: brightness(97%);
}

.backgroundImage:active {
  filter: brightness(94%);
}

.loadingBackground {
  border-color: var(--color-dark300);
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.buttonContainer:hover {
  filter: brightness(97%);
}

.buttonContainer:active {
  filter: brightness(94%);
}

.buttonImage {
  width: 100%;
}

.buttonContents {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.peerCanControlIndicatorButton {
  --size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  background-color: var(--color-dark700);
  border-radius: 25%;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.25);
}

.fullScreenButton {
  --size: 40px;
  height: var(--size);
  width: var(--size);
}

.icon {
  --size: 18px;
  height: var(--size);
  width: var(--size);
  fill: var(--color-light);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 22px;
}

.confirmationText {
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
  color: var(--color-dark);
  max-width: 350px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deleteButton {
  margin-left: 10px;
}

.heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formText {
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 5px;
}

.inputRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.fullInputRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.inputRow .textInputLeft {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
  margin-right: -1px; /* to avoid duplicating border line */
  z-index: 1;
}

.inputRow .textInputRight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
}

.checkboxRow {
  display: flex;
  flex-direction: row;
  max-width: 368px;
  margin-bottom: 30px;
}

.buttonRow {
  display: flex;
}

.cancelButton {
  margin-right: 20px;
  background-color: var(--color-mid-gray);
}

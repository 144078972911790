.nameText {
  margin-left: 10px;
  max-width: 160px;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  font-size: 16px;
  margin-left: 6px;
  margin-right: 10px;
}

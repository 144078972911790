.linkRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.urlTextBox {
  border: 1px solid var(--color-dark200);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 6px 10px 6px 10px;
  height: 24px;
  width: 450px;
  margin-right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.urlText {
  font-family: var(--font-body);
  font-size: 16px;
  color: var(--color-mid-gray);
  white-space: nowrap;
}

.textInput {
  border-radius: 6px;
  font-family: var(--font-body);
  font-size: 16px;
  color: var(--color-dark700);
  border: none;
  padding: 0;
  flex: 1;
}

.instructionsText {
  font-family: var(--font-body);
  font-size: 14px;
  margin-bottom: -23px;
}

.cancelButton {
  margin-left: 14px;
  background-color: var(--color-gray) !important;
}

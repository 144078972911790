.container {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--color-dark);
  align-self: flex-start;
}

.textarea {
  font-family: var(--font-body);
  width: 100%;
  height: 160px;
  background-color: var(--color-white);
  padding: 6px 10px 6px 10px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
  border: 1px solid var(--color-dark100);
  border-radius: 10px;
  outline: 0px solid var(--color-dark400);
  transition: outline 0.1s, border 0.2s;
}

textarea:hover {
  border-color: var(--color-dark400);
}

textarea:focus {
  border-color: var(--color-dark400);
  outline-width: 1px;
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray) !important;
  margin-right: 6px;
}

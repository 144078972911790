.container {
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.icon {
  height: 18px;
  width: 18px;
}

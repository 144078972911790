.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  background-color: var(--color-white);

  border-bottom: 1px solid var(--color-gray);
}

.XIcon {
  --size: 30px;
  height: var(--size);
  width: var(--size);
  margin-left: 82px;
  cursor: pointer;
  fill: var(--color-dark);
  flex-shrink: 0;
  border-radius: 20%;
  transition: background-color 0.2s;
}

.XIcon:hover {
  background-color: var(--color-mid-gray);
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-size: 22px;
  padding: 0 20px;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
}

.actionButton {
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border-radius: 20%;
  transition: background-color 0.2s;
}

.actionButton:hover {
  background-color: var(--color-mid-gray);
}

.switchIcon,
.switchIcon svg {
  color: var(--color-dark);
  fill: var(--color-dark);
}

.deleteButton,
.deleteButton svg {
  color: var(--color-red);
  fill: var(--color-red);
}
.actionButton svg {
  width: 32px;
  height: 32px;
}

.snapshotListContainer {
  overflow-y: auto;
  padding: 26px;
}

.emptyMessage {
  padding: 40px 40px 10px 40px;
  text-align: center;
  color: var(--color-dark300);
}

.monthLabel {
  font-size: 14px;
}

.dayContainer {
  background: var(--color-light-gray);
  border-radius: 12px;
  padding: 10px 15px;
}

.dayContainer:not(:first-child) {
  margin-top: 15px;
}

.snapshotList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-left: -15px;
}

.snapshot {
  display: flex;
  flex-direction: column;
  min-width: 105px;
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
}

.snapshotImgContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background: var(--color-white);
  overflow: hidden;
  margin-bottom: 5px;
}
.snapshotImgContainer:before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}
.snapshotImgContainer > img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.shareButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--color-dark400) 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out, box-shadow 0.1s;
}

.shareButton > img {
  height: 80%;
}

.shareButton.isShared {
  background-color: var(--color-yellow200);
  filter: grayscale(0);
  opacity: 1;
}

.shareButton:not(.isShared) {
  filter: grayscale(1);
  opacity: 0;
}

.snapshot:hover .shareButton:not(.isShared) {
  opacity: 1;
}

.shareButton:hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 2px;
}

.snapshotResourceName {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip {
  background-color: var(--color-light);
  color: var(--color-dark);
  pointer-events: none;
  padding: 2px 7px 1px 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: var(--font-body);
  font-size: 13px;
  border-radius: 26px;
  border: 1px solid var(--color-dark-gray);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 50;
  white-space: nowrap;
  font-weight: initial;
  transition: opacity 0.2s;
}

.tooltip.hide {
  opacity: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.accountText {
  font-family: var(--font-body);
  font-size: 18px;
  color: var(--color-dark700);
}

.linkSection {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100%;
}

.linkRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.urlTextBox {
  border: 1px solid var(--color-light);
  border-radius: 10px;
  background-color: var(--color-light);
  padding: 6px 10px 6px 10px;
  height: 24px;
  max-width: 450px;
  flex: 1;
  margin-right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: text;
}

.messageText {
  font-family: var(--font-body);
  font-size: 18px;
  color: var(--color-dark700);
  margin-bottom: 10px;
}

.urlText {
  user-select: text;
  font-family: var(--font-body);
  color: var(--color-dark700);
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.editIcon {
  height: 22px;
  width: 22px;
  margin-left: 10px;
  cursor: pointer;
}

.signOutButton {
  margin-top: 20px;
  background-color: var(--color-gray) !important;
}

.inviteButton {
  margin-left: 14px;
}

.mailIcon {
  --size: 22px;
  height: var(--size);
  width: var(--size);
  margin-right: 6px;
  fill: var(--color-dark);
}

.paywall {
  z-index: 60;
}

.billingCounter {
  margin-top: 60px;
}

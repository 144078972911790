.floatingContainer {
  background-color: var(--color-white);
  padding: 28px;
  border-radius: 20px;
  box-shadow: 0 0 2px var(--color-dark200);
  display: inline-block;
  z-index: 1;
  transition: filter 0.2s;
}

.floatingContainer.disabled {
  filter: brightness(75%);
}

.linearGradient {
  width: 20px;
  height: 300px;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 auto;
}

.indicator {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid white;
  outline: none;
  box-sizing: border-box;
  transition: outline 0.05s;
}

.indicator:hover,
.indicator:active {
  outline: 3px solid var(--color-dark400);
}

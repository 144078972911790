.containerDebugButtons {
  display: flex;
  gap: 12px;
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid;
}

th,
td {
  padding: 6px;
}

.containersSection {
  margin-top: 40px;
}

.heading {
  font-size: 22px;
  margin-bottom: 24px;
}

.body {
  display: flex;
  margin-bottom: 30px;
  width: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray);
  margin-right: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-body);
  text-align: center;
}

.modal {
  background-color: var(--color-yellow200);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-dark);
}

.xIcon {
  align-self: flex-end;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 12px 28px;
}

.text {
  font-size: 16px;
  width: 450px;
  margin-left: 6px;
  margin-right: 10px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  background: var(--color-yellow200);
  border-radius: 4px;
}

.trialBadge {
  color: var(--color-dark300);
  padding: 0px 10px;
}

.planTag {
  padding: 0px 10px;
  border-radius: 4px;
  color: white;
}

.free {
  background: var(--color-dark100);
}

.plus {
  background: var(--color-green400);
}

.unlimited {
  background: linear-gradient(129deg, #eecf44 0%, #e7a73a 34%, #eecf44 100%);
  text-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
}

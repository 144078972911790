.menuContainer {
  width: 230px;
  max-height: 70vh;
  overflow: visible;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  top: -4px;
  left: 50px;
  transform: translate(-50%, -100%);
  z-index: 21;
}

.menuContainerElevated {
  z-index: 31;
}

.heading {
  font-size: 22px;
  margin-bottom: 30px;
}

.body {
  display: flex;
  margin-bottom: 24px;
}

.side {
  width: 250px;
  padding: 26px 20px 26px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sideHeading {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.textInput {
  font-size: 14px;
  width: 200px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dark200);
  opacity: 1; /* Firefox */
}

.divider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalLine {
  width: 1px;
  height: 80px;
  border-radius: 1px;
  background-color: var(--color-dark100);
  margin: 5px;
}

.orText {
  color: var(--color-dark100);
}

.textContainer {
  height: 45px;
}

.selectedFilesText {
  font-size: 13px;
  line-height: 15px;
  color: var(--color-dark);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 250px;
}

.fileDescriptionText {
  font-size: 13px;
  line-height: 15px;
  color: var(--color-dark300);
}

.chooseFileButton {
  margin-bottom: 20px;
}

.secondaryButton {
  background-color: var(--color-gray);
}

.shareSection {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mainCheckboxText {
  font-size: 14px;
  margin-top: -2px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancelButton {
  background-color: var(--color-gray);
  margin-right: 10px;
}

.button {
  width: 150px;
  cursor: pointer;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

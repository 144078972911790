.button {
  box-sizing: content-box;
  border: 0;
  outline: 0;
  padding: 0;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 0.2s, outline 0.1s;
}

.button.isSelected {
  outline-style: solid;
  outline-color: var(--color-dark700);
}

.button.isToggled {
  outline-style: solid;
  outline-color: var(--color-dark-gray);
}

.button:hover {
  filter: brightness(85%);
}

.dividerContainer {
  display: flex;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: var(--color-dark200);
  align-self: center;
}

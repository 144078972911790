.planContainer {
  width: 384px;
  padding: 28px;
  background: var(--color-white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: var(--font-billing);
  color: var(--color-dark);
}

.planDetails {
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
}

.planHeader {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  font-size: 14px;
}

.planTitle {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
}

.planName {
  display: flex;
  align-items: center;
}

.helpIcon {
  height: 20px;
  width: 20px;
  fill: var(--color-dark);
  margin-left: 8px;
  margin-top: -1px;
}

.usageWarning {
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: var(--color-dark300);
}

.usageWarning svg {
  height: 17px;
  width: 17px;
  margin-top: 1px;
  margin-right: 6px;
  fill: var(--color-dark300);
  flex-shrink: 0;
}

.usageLow:not(.usageReachedMax) {
  color: var(--color-orange);
}

.usageLow:not(.usageReachedMax) svg {
  fill: var(--color-orange);
}

.usageReachedMax {
  color: var(--color-red);
}

.usageReachedMax svg {
  fill: var(--color-red);
}

.planReset {
  color: var(--color-dark200);
  margin-right: 4px;
}

.usageSection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.usageHeaderLabel {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.usageHeaderIcon {
  height: 20px;
  width: 20px;
  fill: var(--color-dark);
  margin-right: 8px;
}

.infoIcon {
  height: 14px;
  width: 14px;
  fill: var(--color-dark200);
  margin-left: 8px;
}

.usageHeaderValue {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.progressContainer {
  width: 100%;
  height: 2px;
  background: var(--color-mid-gray);
  border-radius: 10px;
  overflow: hidden;
}

.progressBar {
  height: 2px;
}

.progressGreen {
  background: var(--color-green400);
}

.progressOrange {
  background: var(--color-orange);
}

.progressRed {
  background: var(--color-red);
}

.managePlan {
  all: unset;
  align-self: flex-start;
  color: var(--color-green400);
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

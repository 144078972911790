.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px 15px 10px 20px;
  align-self: stretch;
}

.container:hover {
  background-color: var(--color-light-gray);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  all: unset;
  color: inherit;
  font: inherit;

  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: var(--color-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  cursor: pointer;
}

.button:hover {
  filter: brightness(97%);
}

.button:active {
  filter: brightness(94%);
}

.secondary {
  background-color: var(--color-gray);
}

.buttonIcon {
  height: 18px;
  width: 18px;
}

.textContainer {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.optionsRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--color-white);
  border: 3px solid transparent;
}

.option:hover {
  background-color: var(--color-gray);
}

.option:active {
  background-color: var(--color-mid-gray);
}

.optionSelected {
  border-color: var(--color-yellow);
}

.optionSelected:hover {
  background-color: var(--color-white);
}

.optionImage {
  height: 100px;
}

.optionLabel {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionIcon {
  height: 16px;
  width: 16px;
  /*DARK*/
  filter: brightness(0) saturate(100%) invert(23%) sepia(12%) saturate(987%)
    hue-rotate(119deg) brightness(90%) contrast(93%);
  margin-right: 5px;
}

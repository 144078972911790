.launchActivityContainer {
  flex: 0 0 auto;
  background: var(--color-gray);
  position: relative;
  display: flex;
  width: 0;
  transition: width 0.5s;
  z-index: 51;
}
.launchActivityContainer.isOpen {
  width: 356px;
}

.shrinkableContainer {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.nonShrinkableContainer {
  flex: 1 0 auto;
  width: 356px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  background-color: var(--color-white);

  border-bottom: 1px solid var(--color-gray);
}

.header {
  font-family: var(--font-body);
  color: var(--color-dark);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.xIcon {
  --size: 30px;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
  fill: var(--color-dark);
  border-radius: 20%;
  transition: background-color 0.2s;
}

.xIcon:hover {
  background-color: var(--color-mid-gray);
}

.body {
  flex: 1 1 auto;
  min-height: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  background-color: var(--color-gray);
  gap: 10px;
}

.card {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  overflow: visible;
  background-color: var(--color-light-gray);
  cursor: pointer;
}

.card:hover {
  background-color: var(--color-light);
}

.thumbnailContainer {
  width: 60px;
  height: 60px;
}

.thumbnailContainer > div {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 60px;
  height: 60px;
}

.cardBody {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin: 8px 0;
}

.card .cardIconContainer {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  height: 20px;
}

.card .cardIcon {
  width: 16px;
  height: 16px;
  fill: var(--color-dark);
}

.card .cardIcon :global(.setFill) {
  fill: var(--color-dark);
}

.card .cardIcon :global(.setStroke) {
  stroke: var(--color-dark);
}

.cardText {
  flex: 1 0 0;
  color: var(--color-dark);
  font-family: var(--font-body);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}

.cardText.breakAll {
  word-break: break-all;
}

.slideButton {
  all: unset;

  --slide-button-height: 242px;
  --slide-button-width: 26px;
  z-index: 26;
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: calc(-0.5 * var(--slide-button-height));
  padding-left: 12px;

  height: var(--slide-button-height);
  width: var(--slide-button-width);
  cursor: pointer;
}

.visibleArea {
  --slide-button-color: var(--color-gray);
  transition: background-color 0.2s;

  background: var(--slide-button-color);
  height: 100%;
  width: 100%;
  border-radius: 18px 0 0 18px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.visibleArea:hover {
  --slide-button-color: var(--color-light-gray);
}

.visibleArea::before,
.visibleArea::after {
  content: "";
  position: absolute;
  right: 0;

  --corner-size: 10px;

  background: transparent;

  width: var(--corner-size);
  height: calc(2 * var(--corner-size));
  transition: box-shadow 0.2s;
}

.visibleArea::before {
  bottom: 100%;

  border-bottom-right-radius: var(--corner-size);

  box-shadow: 0 var(--corner-size) 0 0 var(--slide-button-color);
}

.visibleArea::after {
  top: 100%;

  border-top-right-radius: var(--corner-size);

  box-shadow: 0 calc(-1 * var(--corner-size)) 0 0 var(--slide-button-color);
}

.visibleArea .expandIcon {
  --icon-size: 24px;
  transform: rotate(90deg);
  flex: 0 0 auto;
  width: var(--icon-size);
  height: var(--icon-size);
  transition: transform 0.2s;
}

.visibleArea:hover .expandIcon {
  transform: rotate(90deg) scale(1.2);
}

.launchActivityContainer.isOpen .slideButton .expandIcon {
  transform: rotate(-90deg);
}

.launchActivityContainer.isOpen .slideButton:hover .expandIcon {
  transform: rotate(-90deg) scale(1.2);
}

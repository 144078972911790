.fullScreenWrapper {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.fullScreenWrapper > * {
  pointer-events: auto;
}

:global(.minimizedVideoContainer) {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  z-index: 51;
  cursor: grab;
  touch-action: none;
}

:global(.minimizedVideoContainer) > :not(:last-child) {
  margin-left: -10px;
}

:global(.minimizedVideoContainer):active {
  cursor: grabbing;
}

:global(.fullScreenVideoContainer) {
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  background-color: rgb(25, 38, 36, 0.8); /*dark700, with opacity*/
  transition: background-color 500ms;
  z-index: 25;
  padding: 0 14px;
}

.fullScreenOverlaySpacing {
  flex: 0 0 auto;
  width: 10px;
  background-color: rgb(25, 38, 36, 0.8); /*dark700, with opacity*/
  z-index: 25;
}

.rowTooltip {
  flex: 1 1 0px;
  min-width: 0px;
  height: 84px;
}

.container {
  flex: 1 1 0px;
  min-width: 0px;
  height: 84px;
  background-color: var(--color-gray);
  border-radius: 10px;
  border: 3px solid var(--color-gray);
  box-sizing: border-box;
  display: flex;
  position: relative;

  transition-duration: 0.1s;
  transition-property: box-shadow, background-color;
}

.container.clickable {
  cursor: pointer;
}

.container:not(.displayOnly).clickable:hover,
.container:not(.displayOnly).clickable.tapped {
  box-shadow: var(--color-yellow) 0px 0px 0px 2px;
}

.custom {
  background-color: var(--color-light);
}

.menuOpen {
  background-color: var(--color-mid-gray);
  border-color: var(--color-mid-gray);
}

.container:not(.displayOnly) {
  border: none;
  box-shadow: var(--color-dark100) 0px 0px 0px 1px;
}

.container:not(.displayOnly):hover,
.container:not(.displayOnly).tapped {
  box-shadow: var(--color-dark200) 0px 0px 0px 2px;
}

.container:not(.displayOnly).selected {
  box-shadow: var(--color-yellow) 0px 0px 0px 2px;
  background-color: var(--color-yellow200);
  cursor: default;
}

.container .resourceThumbnail {
  height: 84px;
  width: 84px;
  border-radius: 10px 0 0 10px;
}

.displayOnly {
  background-color: var(--color-white);
  border-color: var(--color-white);
  cursor: default;
  max-width: 100%;
}

.sharedRoom {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-width: 0;
}

.displayOnly .headerAndIcons {
  margin-right: initial;
}

.header {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  font-size: 18px;
  line-height: 25px;
  margin-right: 10px;
}

.headerSharedRoom {
  font-size: 15px;
}

.headerRow {
  justify-content: space-between;
}

.headerRow:not(.headerRowLarge) {
  margin-top: 3px;
}

.headerAndIcons {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 52px;
}

:global(.favoriteResultsContainer) .headerAndIcons {
  margin-right: 20px;
}

.resourceCategoryIcon {
  margin-top: -1px;
}

.iconContainer {
  margin-right: 3px;
  margin-top: -1px;
}

.lockIcon {
  height: 15px;
  width: 15px;
  fill: var(--color-dark);
}

.organizationIcon {
  width: 14px;
}

.attribution {
  color: var(--color-dark300);
  margin-bottom: 4px;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

:global(.favoriteResultsContainer) .attribution {
  margin-right: 20px;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.rightSection {
  flex: 1;
  min-width: 0;
  padding: 5px 5px 5px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actionButtonContainer {
  --button-size: 34px;
  --icon-size: 22px;

  position: absolute;
  right: calc(-1 * var(--button-size) / 3);
  bottom: calc(-1 * var(--button-size) / 3);
  z-index: 1;

  transition-duration: 0.2s;
  transition-property: opacity;
  opacity: 0;
  pointer-events: none;
}

.container:not(.displayOnly):not(.selected):hover .actionButtonContainer,
.container:not(.displayOnly):not(.selected).tapped .actionButtonContainer {
  opacity: 1;
  pointer-events: auto;
}

.actionButton {
  border: none;
  outline: none;
  cursor: pointer;

  height: var(--button-size);
  width: var(--button-size);
  border-radius: 50%;

  /*Overwrite safari ipad default button padding*/
  padding: 0;

  transition-duration: 0.2s;
  transition-property: box-shadow;
  box-shadow: var(--color-light) 0px 0px 0px 0px;
  background-color: var(--color-yellow);

  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton .actionButtonIcon.plusIcon {
  margin: -3px;
}

.actionButton:hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 3px;
}

.actionButton .actionIcon {
  flex: 0 0 auto;
  height: var(--icon-size);
  width: var(--icon-size);
}

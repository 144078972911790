.heading {
  font-size: 22px;
  margin-bottom: 20px;
}

.roomRowsContainer {
  width: 400px;
  height: 250px;
  overflow-y: auto;
  border-top: 1px solid var(--color-dark200);
  border-bottom: 1px solid var(--color-dark200);
  margin-bottom: 20px;
}

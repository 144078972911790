.container {
  flex: 0 0 auto;
  margin-bottom: 16px;
  padding: 14px 24px 0 24px;
}

.topRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.numResultsText {
  color: var(--color-dark300);
  font-size: 14px;
}

.numResultsTextSmall {
  font-size: 13px;
}

.filtersRow {
  display: flex;
}

.filtersRow > div:not(:last-child) {
  margin-right: 10px;
}

.addActivityButton {
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
  --bg-color: var(--color-yellow);
  align-self: flex-end;
  flex: 0 0 auto;
}

.plusIcon {
  height: 20px;
  width: 20px;
}

.buttonContents {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonText {
  padding-top: 2px;
  padding-left: 2px;
}

.resultsGroup {
  display: flex;
  height: 32px;
  padding: 0px 14px;
  align-items: center;
  gap: 14px;
  flex: 1 0 auto;
}

.container .clearFiltersButton {
  color: var(--color-dark);
  font-family: var(--font-body);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  --height: 24px;
  --width: 58px;
  --bg-color: var(--color-white);
  --hover-brightness: 100%;
  box-shadow: var(--color-dark100) 0px 0px 0px 1px;
  margin: 2px;
  flex: 0 0 auto;
  /*Overwrite safari ipad default button padding*/
  padding: 0;
}

.clearFiltersButton:focus,
.clearFiltersButton:not(.disabled):hover {
  box-shadow: var(--color-dark400) 0px 0px 0px 2px;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-yellow100);
  height: 100%;
  box-sizing: border-box;
}

.content {
  position: fixed;
  display: flex;
  padding: 40px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  font-family: var(--font-heading);
  font-size: 36px;
  color: var(--color-dark700);
  margin-bottom: 36px;
}

.logo {
  height: 70px;
  width: 70px;
  margin-right: 20px;
}

.plant {
  height: calc(469px * 0.65);
  width: calc(291px * 0.65);
  position: absolute;
  bottom: 20px;
  right: 50px;
}

/* Organization specific styles */
.header :global(.classroom-clinic) {
  height: 280px;
  /* Negative margin to compensate .svg embedded margins for poster */
  margin: -105px -21px;
}

.header :global(.ohana) {
  height: 280px;
  /* Negative margin to compensate .svg embedded margins for poster */
  margin: -105px -38px;
}

.header :global(.community-bridges-inc) {
  height: 280px;
  /* Negative margin to compensate .png embedded margins for poster */
  margin: -105px -21px;
}

.heading {
  font-size: 22px;
  margin-bottom: 10px;
  width: 400px;
}

.subHeading {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  max-width: 300px;
  align-self: flex-start;
}

div.copyTextBox {
  margin-bottom: 30px;
  width: calc(100% - 20px);
  justify-content: space-between;
}

.emailSection {
  display: flex;
  flex-direction: column;
  background-color: var(--color-light-gray);
  padding: 10px 10px;
  width: 430px;
  margin-bottom: 30px;
  border-radius: 12px;
}

.textInput {
  margin-bottom: 3px;
  flex: 1;
  grid-area: to;
}

.sendButton {
  width: 150px;
  align-self: flex-end;
}

.mailIcon {
  --size: 22px;
  height: var(--size);
  width: var(--size);
  margin-right: 6px;
  fill: var(--color-dark);
}

.mailIcon.disabled {
  fill: var(--color-dark200);
}

.emailPreview {
  text-align: left;
  display: grid;
  grid-template:
    "toLabel to" auto
    "fromLabel from" auto
    "subjectLabel subject" auto
    "body body" auto
    / auto 1fr;
  font-size: 14px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.toLabel {
  grid-area: toLabel;
}

.fromLabel {
  grid-area: fromLabel;
}

.from {
  grid-area: from;
}

.subjectLabel {
  grid-area: subjectLabel;
}

.subject {
  grid-area: subject;
}

.body {
  grid-area: body;
  word-break: break-all;
  margin-bottom: 3px;
}

.body a {
  text-decoration: underline;
}

.container {
  flex: 1 1 0px;
  min-height: 0;

  display: flex;
  flex-direction: column;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 24px 18px 24px;
}

:global(.favoriteResultsContainer) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
}

.noResultsMessage {
  place-self: center;
  margin-top: 20px;
  color: var(--color-dark300);
  font-size: 14px;
}

.loadMoreButton {
  place-self: center;
  cursor: pointer;
  color: var(--color-dark300);
}

.loadMoreButton:hover {
  color: var(--color-dark);
}

.loadingContainer {
  place-self: center;
}

.horizontalLine {
  min-height: 1px;
  background-color: var(--color-gray);
}

.menuContainer {
  width: 160px;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 30px;
  right: -10px;
  /*transform: translate(-50%, -100%);*/
  z-index: 21;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  cursor: default;
}

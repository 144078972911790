.container {
  background-color: var(--color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 45;
  margin-bottom: 10px;
  pointer-events: auto;
}

.subcontainer {
  display: flex;
  flex-direction: row;
}

.button {
  width: 80px;
  height: 30px;
  margin-left: 10px;
  font-size: 14px;
}

.snackbar {
  animation: 0.2s ease-out 0s 1 normal forwards running fade-in;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  background: var(--color-white);
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* This animation is a scale in the horizontal,
 and a slide down in the vertical axis */
.snapshot {
  max-width: 100%;
  height: 140px;
}

.snackbar {
  transition: height 0.5s ease-in, transform 0.5s ease-in;
  width: 250px;
  height: 140px;
  transform: scaleX(100%);
}
.closing {
  height: 0px;
  transform: scaleX(0%);
}

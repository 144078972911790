.container {
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.tooltipContainer {
  height: 100%;
}

.tooltipElementWrapper {
  height: 100%;
}

.leftSection {
  all: unset;
  color: inherit;
  font: inherit;
  cursor: pointer;

  padding-left: 18px;
  padding-right: 18px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  gap: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.2s, background 0.2s;
  opacity: 70%;
}

.leftSection:hover {
  opacity: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.icon {
  width: 24px;
  height: 24px;
  padding-right: 1px;
  fill: var(--color-white);
}

.text {
  text-align: center;
  color: white;
  font-family: var(--font-body);
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  word-wrap: break-word;
}

.divider {
  height: 30%;
  width: 2px;
  background-color: var(--color-white);
  opacity: 0.25;
  border-radius: 1px;
}

.rightSection {
  all: unset;
  color: inherit;
  font: inherit;
  cursor: pointer;

  padding-left: 18px;
  padding-right: 18px;
  height: 100%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s, background 0.2s;
  opacity: 70%;
}

.rightSection:hover {
  opacity: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.zoomIcon {
  width: 24px;
  height: 24px;
  fill: var(--color-white);
}

.singleSection {
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.2s, background 0.2s;
  opacity: 70%;
}

.singleSection:hover {
  opacity: 100%;
  background: rgba(0, 0, 0, 0.8);
}

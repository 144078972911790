.container {
  border: none;
  font: inherit;

  --fg-color: var(--color-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  cursor: pointer;
  padding: 12px 0px;
  border-radius: 10%;
  transition: background-color 0.1s;
  background-color: var(--color-light);
  color: var(--fg-color);
  stroke: var(--fg-color);
  fill: var(--fg-color);
}

.container:hover {
  background-color: var(--color-yellow200);
}

.selected {
  --fg-color: var(--color-green);
}

.top {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 14px;
  text-align: center;
}

.icon {
  --size: 36px;
  height: var(--size);
  width: var(--size);
}

.button {
  --size: 28px;
  /*Overwrite safari ipad default button padding*/
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: var(--size);
  width: var(--size);
  border: 0;
  background-color: transparent;
}

.buttonContainer {
  border-radius: 50%;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
  background-color: var(--color-gray);
}

.buttonContainer:hover {
  box-shadow: 0 0 0 0.2rem var(--color-dark400);
}

.icon {
  --size: 18px;
  height: var(--size);
  width: var(--size);
  fill: var(--color-dark);
}

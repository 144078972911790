.container {
  max-width: 610px;
  min-width: 460px;
  width: calc(40vw + 60px);
  background-color: var(--color-light);
  font-family: var(--font-body);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  color: var(--color-dark);
}

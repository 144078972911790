.button {
  all: unset;
  color: inherit;
  font: inherit;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--button-padding);

  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(0, 0, 0, 1);
}

.button.red {
  /* --color-red with transparency */
  background-color: rgba(202, 89, 74, 0.6);
}

.button.red:hover {
  /* --color-red with transparency */
  background-color: rgba(202, 89, 74, 1);
}

.container {
  position: absolute;
  right: -2px;
  top: -2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.containerHalfWidth {
  flex-direction: column;
  height: 64px;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.icon {
  margin-top: -10px;
  height: 23px;
  width: 23px;
  fill: var(--color-dark300);
  cursor: pointer;
}

.container:hover > .icon {
  filter: brightness(0) saturate(100%) invert(19%) sepia(45%) saturate(321%)
    hue-rotate(119deg) brightness(88%) contrast(86%);
}

.video {
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.localVideo {
  transform: scaleX(-1);
}

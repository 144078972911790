.container {
  display: grid;
  grid-template:
    "nav" auto
    "album" 1fr
    / 1fr;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  flex: 1 1;
  position: relative;
}

@media (max-height: 600px) and (min-aspect-ratio: 1/1) {
  .container {
    grid-template:
      "nav album" 1fr
      / auto 1fr;
  }
  .header {
    display: flex;
  }
}

.header {
  grid-area: nav;
}

.disableControl {
  cursor: not-allowed;
}

.disableControl .header,
.disableControl .albumContainer {
  pointer-events: none;
}
.albumContainer {
  grid-area: album;
  background-color: var(--color-dark);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.album {
  position: relative;
  background: center / contain no-repeat
    url(./../../../../assets/album/album_background.png);
}

.albumTitle {
  position: absolute;
  left: 3%;
  top: 5%;
  width: 46%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.albumTitleImage {
  max-width: 90%;
  max-height: 90%;
}

.leftPage {
  position: absolute;
  left: 3%;
  top: 5%;
  width: 45%;
  height: 90%;
  padding-left: 1%;
  display: grid;
  grid-template:
    "title title" 10%
    "changePageButton snapshotList" 90%
    / 9% 90%;
}

.rightPage {
  position: absolute;
  right: 3%;
  top: 5%;
  width: 45%;
  height: 90%;
  padding-right: 1%;
  display: grid;
  grid-template:
    "title title" 10%
    "snapshotList changePageButton" 90%
    / 90% 9%;
}

.pageTitle {
  grid-area: title;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  font-family: var(--font-body);
}

.snapshotList {
  grid-area: snapshotList;
  display: grid;
  padding: 5%;
  gap: 5%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.noSnapshotsPage {
  position: absolute;
  right: 3%;
  top: 5%;
  width: 46%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noSnapshots {
  text-align: center;
  width: 60%;
}

.changePageButton {
  grid-area: changePageButton;

  width: 90%;
  height: 90%;
  place-self: center;

  background: center / contain no-repeat
    url(./../../../../assets/album/button_background.png);

  display: flex;
  justify-content: center;
  align-items: center;
}

.changePageButton img {
  max-width: 50%;
  max-height: 50%;
}

.container:not(.disableControl) .changePageButton img {
  cursor: pointer;
}

.leftPage .changePageButton img {
  transform: scaleX(-1);
  margin-left: -15%;
}

.snapshotThumbnail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.snapshotThumbnail img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  border-style: solid;
  border-color: var(--color-dark);
  box-sizing: border-box;
}

.container:not(.disableControl) .snapshotThumbnail img {
  cursor: pointer;
}

.snapshotDetails {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-yellow200);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.snapshotDetails:not(.zooming) {
  justify-content: center;
}

:not(.zooming) > .snapshot {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  background: var(--color-yellow);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container:not(.disableControl) .closeButton {
  cursor: pointer;
}
.XIcon {
  max-width: 75%;
  max-height: 75%;
}

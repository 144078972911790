.container {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
}

.childrenContainer {
  display: flex;
  min-width: 0;
}

.tooltipContainer {
  background-color: var(--color-light);
  color: var(--color-dark);
  pointer-events: none;
  padding: 2px 7px 2px 7px;
  font-family: var(--font-body);
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 71;
  white-space: nowrap;
  font-weight: initial;
}

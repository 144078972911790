.container {
  --list-item-separator: 1px solid var(--color-mid-gray);
  margin-top: 0px;
  display: grid;
  grid-template:
    "search space new" auto
    "list list list" 1fr
    "selected selected selected" auto /
    1fr 10px 135px;
  width: 400px;
}

.searchInput {
  grid-area: search;
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
}

.searchInput > input {
  width: 100%;
  padding-left: 35px;
  margin-bottom: 0;
  border-radius: 24px;
  height: 18px;
  font-size: 15px;
}

.searchInput > .searchIcon {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 6px;
  z-index: 1;
  fill: var(--color-dark400);
}

.searchInput > .clearButton {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 36px;
  cursor: pointer;
}

.clearButton > .xIcon {
  height: 16px;
  width: 16px;
  z-index: 1;
  fill: var(--color-dark300);
  transition: fill 0.2s;
}

.clearButton:hover > .xIcon {
  fill: var(--color-dark500);
}

.newClient {
  --height: 32px;
  grid-area: new;
  width: 100%;
  background-color: var(--color-yellow400);
  font-size: 16px;
}

.newClientInList {
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
  transition: opacity 0.2s;
}

.newClientInList:hover {
  opacity: 0.8;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.list {
  grid-area: list;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  list-style: none;
  height: 266px;
  font-size: 17px;
  margin: 16px 0;
  padding: 0;
}

.listItem {
  text-align: left;
  padding: 12px;
  cursor: pointer;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 0.2s;
}

.listItem {
  border-top: var(--list-item-separator);
}

.listItem:last-child {
  border-bottom: var(--list-item-separator);
}

.listItem.loaded {
  background-color: var(--color-yellow100);
}

.listItem.selected {
  background-color: var(--color-yellow200);
}

.listItem:not(.selected):hover {
  background-color: var(--color-gray);
}

.emptyListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.emptyList {
  white-space: nowrap;
  flex: 0 0 0;
  color: var(--color-dark);
  margin: 8px 0;
}

.tooltipWrapperDiv {
  width: 100%;
}
